<script>
	import { browser } from '$app/environment';
	import { afterNavigate } from '$app/navigation';

	afterNavigate(() => {
		if (browser && typeof window.saq !== 'undefined') {
			window.saq('ts', 'yx_j3wgAt4V_yhvtMskVyw');
		}
	});
</script>

<svelte:head>
	<script>
		window.saq = function () {
			window.saq.callMethod
				? window.saq.callMethod.apply(window.saq, arguments)
				: window.saq.queue.push(arguments);
		};
		if (!window._saq) window._saq = window.saq;
		window.saq.push = window.saq;
		window.saq.loaded = !0;
		window.saq.version = '1.0';
		window.saq.queue = [];
	</script>

	<!-- eslint-disable-next-line -->
	{@html `<script async defer src="/static/js/events.js"></script>`}
</svelte:head>
