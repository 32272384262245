<script>
	import { page } from '$app/stores';

	import { PUBLIC_ENV } from '$env/static/public';
	import {
		getGbraidClickId,
		getGclid,
		getWbraidClickId,
	} from '$lib/Components/Pixels/googleAds.js';
	import Cloudflare from './Cloudflare.svelte';
	import PiwikPro from './PiwikPro.svelte';
	import StackAdapt from './StackAdapt.svelte';
	import Zaraz from './Zaraz.svelte';
	import { getQuoraClickId } from './quora';
	import { getRedditClickId } from './reddit';

	function getClickIds(url) {
		getGclid(url);
		getGbraidClickId(url);
		getWbraidClickId(url);
		getQuoraClickId(url);
		getRedditClickId(url);
	}
	$: getClickIds($page.url);
</script>

{#if PUBLIC_ENV == 'production'}
	<Cloudflare />
	<StackAdapt />
	<PiwikPro />
	<Zaraz />
{/if}
